<template>
  <div
    class="flex flex-auto h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
  >
    <button
      type="button"
      class="-m-2.5 p-2.5 text-gray-700 md:hidden"
      @click="$emit('toggle-sidebar')"
    >
      <span class="sr-only">Open sidebar</span>
      <BaseIcon name="heroicons:bars-3" class="h-6 w-6" aria-hidden="true" />
    </button>

    <!-- Separator -->
    <div class="h-6 w-px bg-gray-900/10 md:hidden" aria-hidden="true" />

    <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
      <form class="relative flex flex-1" @submit.prevent="handleSearch">
        <label for="search-field" class="sr-only">Search</label>
        <BaseIcon
          name="heroicons:magnifying-glass"
          class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
          aria-hidden="true"
        />
        <input
          class="outline-none block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
          placeholder="Search..."
          type="search"
          name="term"
          v-model="term"
          @keyup="handleSearchThrottled"
        />
      </form>
      <div class="flex items-center gap-x-4 lg:gap-x-6">
        <a
          href="https://chrome.google.com/webstore/detail/promptdrive/dccfgkejoobijclmdfefmigflabbjcbg"
          target="_BLANK"
          v-if="!user.extension_installed"
        >
          <img class="h-10" src="~/assets/chrome-extension.png" />
        </a>
        <template v-if="type === 'prompt'">
          <BaseButton
            iconLeft="heroicons:plus"
            class="ml-2"
            size="sm"
            @click="handleNew"
          >
            <span class="hidden sm:inline-block">New Prompt</span>
          </BaseButton>
        </template>
        <template v-if="type === 'chat'">
          <BaseButton
            iconLeft="heroicons:plus"
            class="ml-2"
            @click="handleNewChat"
            size="sm"
          >
            <span class="hidden sm:inline-block">New Chat</span>
          </BaseButton>
        </template>
        <BaseLink href="/prompts/home">
          <BaseIcon name="heroicons:question-mark-circle" class="w-9 h-9" />
        </BaseLink>
        <UserDropdown />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDebounceFn } from '@vueuse/core'
import useCurrentUser from '~/composables/useCurrentUser'
import useEventBus, { EVENTS } from '~/composables/useEventBus'
import useResponsive from '~/composables/useResponsive'

const { user } = useCurrentUser()
const props = defineProps({
  type: {
    type: String,
    default: 'prompt',
  },
  term: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['search'])
const term = ref(props.term || '')

watch(
  () => props.term,
  (value) => {
    term.value = value
  }
)

const handleSearch = async () => {
  if (term.value.length === 0) emit('search', { data: null })
  if (term.value.length < 1) return

  emit('search', { term: term.value })
}

const handleNew = () => {
  useEventBus({ name: EVENTS.NEW_PROMPT }).emit({
    prompt: { category: 'chatgpt' },
  })
}

const handleNewChat = () => {
  useEventBus({ name: EVENTS.NEW_CHAT }).emit({ chat: {} })
}

const handleSearchThrottled = useDebounceFn(handleSearch, 500)
</script>
